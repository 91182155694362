@font-face {
  font-family: 'minionPro';
  src:  url('fonts/MinionPro-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'minionProItalic';
  src:  url('fonts/MinionPro-It.otf') format('truetype');
}

@font-face {
  font-family: 'playfairDisplayBold';
  src:  url('fonts/PlayfairDisplay-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'moon';
  src:  url('fonts/Moon2.0-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'proximaNovaReg';
  src:  url('fonts/Proxima_nova_reg-webfont.ttf') format('truetype');
}
