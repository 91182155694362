@font-face {
  font-family: 'minionPro';
  src: url("fonts/MinionPro-Regular.otf") format("truetype"); }

@font-face {
  font-family: 'minionProItalic';
  src: url("fonts/MinionPro-It.otf") format("truetype"); }

@font-face {
  font-family: 'playfairDisplayBold';
  src: url("fonts/PlayfairDisplay-Bold.otf") format("truetype"); }

@font-face {
  font-family: 'moon';
  src: url("fonts/Moon2.0-Regular.otf") format("truetype"); }

@font-face {
  font-family: 'proximaNovaReg';
  src: url("fonts/Proxima_nova_reg-webfont.ttf") format("truetype"); }

.landingPage .carousel {
  overflow: hidden; }
  .landingPage .carousel img:not(.leaf) {
    max-width: 100%;
    max-height: 100%; }
  .landingPage .carousel .paperleaf {
    position: absolute;
    width: 100%;
    text-align: center;
    font-family: minionPro;
    top: 30%; }
    .landingPage .carousel .paperleaf .logo .leaf {
      width: 100%; }
    .landingPage .carousel .paperleaf .slogan {
      color: #ffffff;
      font-family: proximaNovaReg;
      font-size: 1.6vw;
      margin: -3vw;
      text-transform: uppercase;
      letter-spacing: 25px; }
  .landingPage .carousel .mote {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 85%; }
    .landingPage .carousel .mote h3 {
      color: #ffffff;
      font-family: minionProItalic;
      font-style: italic;
      font-size: 1.6vw;
      letter-spacing: 6px; }
  .landingPage .carousel .down {
    color: #ffffff;
    font-size: 1vw;
    position: absolute;
    width: 100%;
    padding: 10px;
    top: 95%; }
  .landingPage .carousel .overlayTeal {
    background-color: #003e45;
    opacity: 0.6;
    width: 100%;
    position: absolute; }
  .landingPage .carousel .overlayBlack {
    background-color: #000;
    opacity: 0.3;
    width: 100%;
    position: absolute; }

.landingPage .siteRoute {
  height: 400px;
  text-align: center; }
  .landingPage .siteRoute button {
    border: none;
    background-color: #003e45;
    font-family: proximaNovaReg;
    letter-spacing: 2px;
    width: 100%;
    box-shadow: 3px 5px 10px grey;
    height: 50px; }

.landingPage .headding {
  height: 287px;
  text-align: center;
  width: 100%;
  font-size: 60px;
  font-family: playfairDisplayBold; }
  .landingPage .headding h1 {
    color: #003e45;
    font-size: 4vw; }

.landingPage .process .type {
  padding: 5px; }
  .landingPage .process .type .name {
    font-family: proximaNovaReg;
    letter-spacing: 1px;
    font-size: 15px; }

@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?g9wt8m");
  src: url("fonts/icomoon.eot?g9wt8m#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?g9wt8m") format("truetype"), url("fonts/icomoon.woff?g9wt8m") format("woff"), url("fonts/icomoon.svg?g9wt8m#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-paper-plane-o:before {
  content: "\e902"; }

.icon-menu:before {
  content: "\e900"; }

.icon-leaf:before {
  content: "\e901"; }

.layoutBanner {
  height: 250px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px; }
  .layoutBanner img {
    /* Set rules to fill background */
    min-height: 250px;
    min-width: 2048px;
    /* Set up proportionate scaling */
    width: 100%;
    /* Set up positioning */
    position: relative;
    top: -150px;
    left: 0; }
  .layoutBanner .title {
    font-family: proximaNovaReg;
    letter-spacing: 1px;
    position: absolute;
    width: 100%;
    text-align: center;
    letter-spacing: 3px;
    top: 85px;
    color: white;
    font-size: 50px;
    z-index: 888; }
  .layoutBanner .overlayTeal {
    background-color: #003e45;
    opacity: 0.6;
    width: 100%;
    height: 250px;
    position: absolute;
    z-index: 777; }
  .layoutBanner .overlayBlack {
    background-color: #000;
    opacity: 0.3;
    width: 100%;
    height: 250px;
    position: absolute;
    z-index: 666; }

.menu-burger {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 30px;
  color: white;
  z-index: 999999;
  cursor: pointer; }

.menu-dropdown {
  text-transform: capitalize;
  cursor: pointer; }

.view {
  margin-bottom: 50px; }

.about {
  font-family: proximaNovaReg; }
  .about .disclamer {
    padding: 10px;
    letter-spacing: 1px;
    font-size: 18px; }
    .about .disclamer img {
      width: 100%; }
    .about .disclamer p {
      padding: 20px;
      text-align: justify;
      font-family: proximaNovaReg; }
    .about .disclamer h1 {
      padding: 0 20px; }
  .about .specialties {
    margin: 20px 0; }
    .about .specialties .title {
      text-align: center;
      font-weight: bold;
      font-size: 30px;
      margin: 20px 0; }
    .about .specialties .specialList {
      text-align: center; }
    .about .specialties .left-list, .about .specialties .right-list {
      padding: 10px; }
      .about .specialties .left-list .icon-leaf, .about .specialties .right-list .icon-leaf {
        font-size: 24px;
        color: #003e45; }

.contact {
  font-family: proximaNovaReg; }
  .contact .desclamer1, .contact .desclamer2 {
    text-align: center;
    letter-spacing: 1px;
    font-size: 18px;
    padding: 10px 0; }
  .contact .form {
    letter-spacing: 2px; }
    .contact .form input {
      width: 100%; }
    .contact .form .ant-form-item, .contact .form .submit {
      padding: 0 5px; }
    .contact .form .ant-form-item-label {
      font-family: proximaNovaReg;
      font-size: 16px; }
    .contact .form .submit button {
      border: none;
      background-color: #003e45;
      font-family: proximaNovaReg;
      letter-spacing: 2px;
      width: 100%;
      height: 50px;
      font-size: 20px; }
  .contact .contactUs {
    margin: 30px 0; }
    .contact .contactUs .section {
      padding-bottom: 20px; }
  .contact .address {
    letter-spacing: 2px;
    padding: 10px; }

.portfolio {
  font-family: proximaNovaReg; }
  .portfolio .image {
    overflow: hidden;
    height: 323px;
    position: relative;
    cursor: pointer;
    padding: 10px; }
    .portfolio .image:hover {
      padding: 5px; }
      .portfolio .image:hover .eye {
        opacity: 0.8; }
    .portfolio .image img {
      width: 100%;
      padding: 5px; }
      .portfolio .image img:hover {
        opacity: 0.8; }
    .portfolio .image .eye {
      position: absolute;
      top: 50%;
      text-align: center;
      font-size: 40px;
      width: 100%;
      height: 100%;
      color: white;
      opacity: 0;
      z-index: 99; }
    .portfolio .image .type {
      position: absolute;
      top: 10px;
      left: 10px;
      text-transform: uppercase; }
  .portfolio .gallery {
    padding: 10px; }
    .portfolio .gallery .ant-radio-button-wrapper-checked {
      border: none !important;
      background-color: #003e45 !important;
      font-family: proximaNovaReg !important;
      letter-spacing: 2px !important; }
    .portfolio .gallery .ProcessFilter {
      text-align: center; }

.pictureModal img {
  width: 100%; }

.slider .carousel {
  margin: 50px auto !important; }

.slider .ProcessFilter {
  text-align: center;
  font-family: proximaNovaReg;
  letter-spacing: 1px; }

.slider .imageGallery {
  padding: 5% 20%; }

body {
  background-color: #fffef8 !important;
  scroll-behavior: smooth !important; }

.content {
  background-color: #fffef8; }
