.landingPage {
	.carousel {
		overflow: hidden;
		img:not(.leaf) {
			max-width: 100%;
			max-height: 100%;
		}
		.paperleaf{
			position: absolute;
			width: 100%;
			text-align: center;
			font-family: minionPro;
			top: 30%;
			.logo{
				.leaf{
					width: 100%;
				}
			}
			.slogan {
				color: #ffffff;
				font-family: proximaNovaReg;
				font-size: 1.6vw;
				margin: -3vw;
				text-transform: uppercase;
				letter-spacing: 25px;

			}
		}
		.mote {
			position: absolute;
			text-align: center;
			width: 100%;
			top: 85%;
			h3{
				color: #ffffff;
				font-family: minionProItalic;
				font-style: italic;
				font-size: 1.6vw;
				letter-spacing: 6px;
			}
		}
		.down {
			color: #ffffff;
			font-size: 1vw;
			position: absolute;
			width: 100%;
			padding: 10px;
			top: 95%;
		}
		.overlayTeal{
			background-color: #003e45;
			opacity: 0.6;
			width: 100%;
			position: absolute;
		}
		.overlayBlack{
			background-color: #000;
			opacity: 0.3;
			width: 100%;
			position: absolute;
		}
	}
	.siteRoute {
		height: 400px;
		text-align: center;
		button {
			border: none;
			background-color: #003e45;
			font-family: proximaNovaReg;
			letter-spacing: 2px;
			width: 100%;
			box-shadow: 3px 5px 10px grey;
			height: 50px;
		}
	}
	.headding{
		height: 287px;
		text-align: center;
		width: 100%;
		font-size: 60px;
		font-family: playfairDisplayBold;
		h1{
			color: #003e45;
			font-size: 4vw;
		}
			
	}
	.process{
		.type{
			padding: 5px;
			.name {
				font-family: proximaNovaReg;
				letter-spacing: 1px;
				font-size: 15px;
			}
		}
	}
}