.slider {
	.carousel {
		margin: 50px auto!important;
	}
	.ProcessFilter {
		text-align: center;
		font-family: proximaNovaReg;
		letter-spacing: 1px;
	}
	.imageGallery {
		padding: 5% 20%;
	}
}