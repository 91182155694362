.portfolio{
		font-family: proximaNovaReg;
	.image {
		overflow: hidden;
		height: 323px;
		position: relative;
		cursor: pointer;
		padding: 10px;
		&:hover {
			padding: 5px;
			.eye {
					opacity: 0.8;
			}

		}
		img{
			width: 100%;
			padding: 5px;
			&:hover {
				opacity: 0.8;
			}
		}
		.eye {
			position: absolute;
			top: 50%;
			text-align: center;
			font-size: 40px;
			width: 100%;
			height: 100%;
			color: white;
			opacity: 0;
			z-index: 99;
		}
		.type {
			position: absolute;
			top: 10px;
			left: 10px;
			text-transform: uppercase;
		}
	}
	.gallery {
		padding: 10px;

		.ant-radio-button-wrapper-checked {
			border: none !important;
			background-color: #003e45 !important;
			font-family: proximaNovaReg !important;
			letter-spacing: 2px !important;
		}
		.ProcessFilter {
			text-align: center;
		}
	}
}

.pictureModal{
	img{
		width: 100%;
	}
}