.about {
		font-family: proximaNovaReg;
	.disclamer{
		padding: 10px;
		letter-spacing: 1px;
		font-size: 18px;
		
		img{
			width: 100%;
		}
		p {
			padding: 20px;
			text-align: justify;
			font-family: proximaNovaReg;
		}
		h1 {
			padding: 0 20px;
		}

	}
	.specialties {
		margin: 20px 0;
		.title{
			text-align: center;
			font-weight: bold;
			font-size: 30px;
			margin: 20px 0;
		}
		.specialList{
			text-align: center;
		}
		.left-list, .right-list {
			padding: 10px;
			.icon-leaf {
				font-size: 24px;
				color: #003e45;
			}
		}
	}
}