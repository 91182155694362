.layoutBanner {
	height: 250px;
	width: 100%;
	overflow: hidden;
	margin-bottom: 50px;
	img{
		/* Set rules to fill background */
		min-height: 250px;
		min-width: 2048px;

		/* Set up proportionate scaling */
		width: 100%;

		/* Set up positioning */
		position: relative;
		top: -150px;
		left: 0;
	}
	.title{
		font-family: proximaNovaReg;
		letter-spacing: 1px;
		position: absolute;
		width: 100%;
		text-align: center;
		letter-spacing: 3px;
		top: 85px;
		color: white;
		font-size: 50px;
		z-index: 888;
	}
	.overlayTeal{
		background-color: #003e45;
		opacity: 0.6;
		width: 100%;
		height: 250px;
		position: absolute;
		z-index: 777;
	}
	.overlayBlack{
		background-color: #000;
		opacity: 0.3;
		width: 100%;
		height: 250px;
		position: absolute;
		z-index: 666;
	}
}

.menu-burger{
	position: absolute;
	top: 10px;
	left: 10px;
	font-size: 30px;
	color: white;
	z-index: 999999;
	cursor: pointer;
}

.menu-dropdown {
	text-transform: capitalize;
	cursor: pointer;
}

.view {
	margin-bottom: 50px;
}