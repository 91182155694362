.contact {
	font-family: proximaNovaReg;
	.desclamer1, .desclamer2 {
		text-align: center;
		letter-spacing: 1px;
		font-size: 18px;
		padding: 10px 0;
	}
	.form {
		letter-spacing: 2px;
		input{
			width: 100%;
		}
		.ant-form-item, .submit{
			padding: 0 5px;
		}
		.ant-form-item-label{
			font-family: proximaNovaReg;
			font-size: 16px;
		}
		.submit {
			button {
				border: none;
				background-color: #003e45;
				font-family: proximaNovaReg;
				letter-spacing: 2px;
				width: 100%;
				height: 50px;
				font-size: 20px;
			}
		}
	}
	.contactUs {
		margin: 30px 0;
		.section{
			padding-bottom: 20px;
		}
	}
	.address {
		letter-spacing: 2px;
		padding: 10px;
	}
}